import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProjectItem} from '../../shared/models/projectItem';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() public projects: ProjectItem[] = [];
  @Output() public projectClicked: EventEmitter<ProjectItem> = new EventEmitter<ProjectItem>();
  public selectedProject: ProjectItem;

  constructor() {
  }

  ngOnInit() {
    this.selectedProject = this.projects[0];
  }

  onProjectClicked(project: ProjectItem) {
    this.selectedProject = project;
    this.projectClicked.emit(project);
  }

}
