import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectsMobileComponent} from './projects-mobile.component';
import {CoreModule} from '../core/core.module';

@NgModule({
  declarations: [ProjectsMobileComponent],
  imports: [
    CommonModule,
    CoreModule,

  ],
  exports: [ProjectsMobileComponent]
})
export class ProjectsMobileModule {
}
