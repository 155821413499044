import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectViewComponent} from './project-view.component';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from '../app-routing.module';

@NgModule({
  declarations: [ProjectViewComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
  ],
  exports: [ProjectViewComponent]

})
export class ProjectViewModule {
}
