import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProjectItem} from '../shared/models/projectItem';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent implements OnInit {

  @Input() project: ProjectItem;

  constructor() {
  }

  ngOnInit() {
  }


}
