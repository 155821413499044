import {Component, OnInit} from '@angular/core';
import {DataService} from './core/data.service';
import {ProjectItem} from './shared/models/projectItem';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public projects: ProjectItem[];
  public selectedProject: ProjectItem;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.fetchProjects().subscribe(projects => this.projects = projects);
    this.selectedProject = this.projects[0];
  }

  onProjectClicked(project) {
    this.selectedProject = project;
  }

}
