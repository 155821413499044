export class ProjectItem {

  public name: string;
  public img: string;
  public bg: string;
  public url: string;
  public description: string;

  constructor(name: string, img: string, bg: string, url: string, description: string) {
    this.name = name;
    this.bg = bg;
    this.img = img;
    this.url = url;
    this.description = description;
  }
}
