import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {QuoteComponent} from './quote/quote.component';
import {ProjectViewModule} from './project-view/project-view.module';
import {ProjectsMobileComponent} from './projects-mobile/projects-mobile.component';
import {ProjectsMobileModule} from './projects-mobile/projects-mobile.module';

@NgModule({
  declarations: [
    AppComponent,
    QuoteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ProjectViewModule,
    ProjectsMobileModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
