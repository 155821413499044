import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  public skills: { name: string, value: number }[];

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
  }

}
