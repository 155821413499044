import {Injectable} from '@angular/core';
import {ProjectItem} from '../shared/models/projectItem';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private readonly DOMAIN: string = '.tzamir.com';

  private readonly DESC_WATCH: string = 'Looking for upcoming movies? Searching for your next movie to watch? Well, \'WatchMe\' is here for you. Using TMDb free APIs, you can search by name, filter by genre, watch trailers, see user ratings and more.';
  private readonly DESC_KEEPLAYING: string = 'KeepPlaying lets you hear your favorite music for free and without any commercials. No strings attached, just keep playing.';
  private readonly DESC_GTA: string = 'Showcase for the popular YouTube channel "GeeMeeK", where you can find a convenient display of the recent uploads and top videos, as well with links to get the items in the videos.';
  private readonly DESC_DASHBARD: string = 'A dashboard that has the ability to display and organize live data, using NGX-CHARTS.';
  private readonly DESC_TOMS_STORE: string = 'A fictional store that contains the greatest TV shows of the 90\'s. Combining Angular 2+, Bootstrap, NodeJS, Express and MongoDB.';
  private readonly DESC_PLAYIT: string = 'Don\'t trust only on big gaming websites reviews, and spend some time on reading real users reviews. \'Playit\' helps you to find your next video game.';

  constructor() {
  }

  fetchProjects(): Observable<ProjectItem[]> {
    return Observable.create(observer => {
      const result: ProjectItem[] = [
        new ProjectItem('KeePlaying', 'project_keeplaying.png', 'bg__keeplaying', 'keeplaying.online', this.DESC_KEEPLAYING),
        new ProjectItem('WatchMe', 'project_watchme.png', 'bg__watchme', 'watchme' + this.DOMAIN, this.DESC_WATCH),
        new ProjectItem('Dashboard', 'project_dashboard.png', 'bg__dashboard', 'dashboard' + this.DOMAIN, this.DESC_DASHBARD),
        new ProjectItem('GTAbox', 'project_gtabox.png', 'bg__gtabox', 'gtabox' + this.DOMAIN, this.DESC_GTA),
        new ProjectItem('Tom\'s Store', 'project_tomstore.png', 'bg__tomstore', 'tomstore' + this.DOMAIN, this.DESC_TOMS_STORE),
        new ProjectItem('Playit', 'project_playit.png', 'bg__playit', 'youtube.com/watch?v=Cg5IkeO3860', this.DESC_PLAYIT),
      ];
      observer.next(result);
      observer.complete();
    });
  }
}
