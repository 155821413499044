import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

  @ViewChild('skill') public skill: ElementRef;
  @Input() name: string;
  @Input() value: number;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.renderer.setStyle(this.skill.nativeElement, 'width', this.value + '%');
    }, 500);
  }

}
