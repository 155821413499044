import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavComponent} from './side-nav/side-nav.component';
import {SkillComponent} from './side-nav/skill/skill.component';
import { MenuComponent } from './menu/menu.component';

@NgModule({
  declarations: [SideNavComponent, SkillComponent, MenuComponent],
  imports: [
    CommonModule,
  ],

  exports: [SideNavComponent, MenuComponent]
})
export class CoreModule {
}
