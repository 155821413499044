import {Component, Input, OnInit} from '@angular/core';
import {ProjectItem} from '../shared/models/projectItem';

@Component({
  selector: 'app-projects-mobile',
  templateUrl: './projects-mobile.component.html',
  styleUrls: ['./projects-mobile.component.scss']
})
export class ProjectsMobileComponent implements OnInit {

  @Input() public projects: ProjectItem[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
